import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../store'
import { theme } from '../../theme'

export function StatusMessage(props: { containerWidth?: string }) {
    const show = useSelector((state: ReduxStoreState) => state.notification.show)
    const message = useSelector((state: ReduxStoreState) => state.notification.message)
    const type = useSelector((state: ReduxStoreState) => state.notification.type)
    // console.log('message', message)
    const [isShown, setIsShown] = useState(false)
    const [fade, setFade] = useState(false)
    useEffect(() => {
        if (show) {
            setIsShown(true)
            setFade(true)
        } else {
            setFade(false)
            setTimeout(() => {
                setIsShown(false)
            }, 500)
        }
    }, [show])

    return (
        <>
            {isShown && (
                <div className={'message-container'}>
                    <div className={`message ${fade ? 'fade-in' : 'fade-out'}`}>
                        <i className="material-icons">check</i>
                        <span>{message}</span>
                    </div>
                </div>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .message-container {
                    position: fixed;
                    left: 0;
                    top: 150px;
                    z-index: 100000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: ${props.containerWidth || '100%'};
                }
                .message {
                    width: auto;
                    padding: 18px;

                    display: none;
                    background: ${type == 'info' ? '#2ed47a;' : theme.colors.red};
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    color: white;
                    align-items: center;

                    font-size: 14px;
                    line-height: 14px;
                    height: 40px;
                }

                @keyframes fadeInAnimation {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                @keyframes fadeOutAnimation {
                    0% {
                        opacity: 1;
                    }
                    99% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 0;
                        display: none;
                    }
                }
                .fade-in {
                    animation: fadeInAnimation ease 1s forwards;
                    display: flex;
                }
                .fade-out {
                    display: flex;
                    animation: fadeOutAnimation ease 1s forwards;
                }
            `}</style>
            <style jsx>{`
                .message-container {
                    position: fixed;
                    left: 0;
                    top: 150px;
                    z-index: 100000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: ${props.containerWidth || '100%'};
                }
                .message {
                    width: auto;
                    padding: 18px;

                    display: none;
                    background: ${type == 'info' ? '#2ed47a;' : theme.colors.red};
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    color: white;
                    align-items: center;

                    font-size: 14px;
                    line-height: 14px;
                    height: 40px;
                }

                @keyframes fadeInAnimation {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                @keyframes fadeOutAnimation {
                    0% {
                        opacity: 1;
                    }
                    99% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 0;
                        display: none;
                    }
                }
                .fade-in {
                    animation: fadeInAnimation ease 500ms forwards;
                    display: flex;
                }
                .fade-out {
                    display: flex;
                    animation: fadeOutAnimation ease 500ms forwards;
                }
            `}</style>
        </>
    )
}
