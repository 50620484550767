import React from 'react'
import { FieldRenderProps } from 'react-final-form'

// @ts-ignore
export default function InputAdapter({ input, meta, className, ...rest }: FieldRenderProps<any>) {
    const hasError = meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)
    return (
        <React.Fragment>
            <input
                {...input}
                {...rest}
                className={`${className} ${hasError ? 'error' : ''}`}
                data-testid={input.name}
            />
            {hasError && (
                <span className={'errorText'}>
                    {meta.error}
                    {meta.submitError}
                </span>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .errorText {
                    color: #cf6679;
                    position: absolute;
                    font-size: 11px;
                }
            `}</style>
        </React.Fragment>
    )
}
