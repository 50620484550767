export const colors = {
    blue: '#5086fb',
    white: '#ffffff',
    bgGrey: '#14161B',
    red: '#cf6679',
    green: '#67d554',
}
export const theme = {
    colors,
    chartLineColor: colors.blue,
    defaultTextColor: colors.bgGrey,
    textHighlightColor: colors.blue,
    defaultFont: 'Poppins, Arial, Helvetica Neue, Helvetica, sans-serif',
}
